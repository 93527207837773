<template>
  <a v-if="shouldDisplay" :href="imageLink" class="ml-auto">
    <img
      :src="imageSrc"
      class="column is-header-ad is-hidden-mobile px-4"
      alt="advertisement"
    >
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      ads: (state) => state.advertisements.leaderboard_ads,
    }),
    shouldDisplay() {
      return !!this.ads?.length;
    },
    imageSrc() {
      return Array.from(this.ads)[0].src;
    },
    imageLink() {
      return Array.from(this.ads)[0].link;
    },
  },
};
</script>
